import Service from "../Service";

const resource = "admonitiondocument/";

export default {
  save(adc, requestID) {   
    return Service.post(resource + "save", adc, {
      params: { requestID: requestID },
    });
  },

  pagination(dtr, requestID) {
    console.log('llendo al servicio')
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },

};