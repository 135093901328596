<template>
  <v-card flat tile>
    <v-card-text class="pa-0">
      <v-col lg="12">
        <v-card-text class="px-3">
          <v-container>
            <v-row>
              <v-col cols="3" class="s-col-form">
                <s-select-definition
                  :def="1052"
                  v-model="adm.TypeAdmonition"
                  label="Tipo Amonestación"
                  @input="selectAdmonition($event)"
                  ref="definitionadm"
                />
              </v-col>
              <v-col cols="2" class="s-col-form">
                <s-toolbar-worker
                  v-model="adm.PrsDocumentNumber"
                  @returnPerson="GetReturnPerson($event, adm)"
                  addCtrEnd
                  addCtrBegin
                  :addfilter="this.$fun.getSecurity().IsLevelAdmin"
                  noCamera
                  noEdit
                  :isValidation="1"
                />
              </v-col>
              <v-col cols="5" class="s-col-form">
                <s-text label="Trabajador" disabled v-model="adm.WkrName" />
              </v-col>
              <v-col cols="2" class="s-col-form">
                <v-switch
                  v-model="showMessages"
                  :color="
                    showMessages == 1
                      ? 'primary'
                      : showMessages == 2
                      ? 'red'
                      : ''
                  "
                  :label="
                    showMessages == 1
                      ? 'Contrato Activo'
                      : showMessages == 2
                      ? 'Contrato Baja'
                      : 'Sin Registro'
                  "
                  disabled
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="s-col-form">
                <s-textarea
                  label="Descripción de Falta"
                  v-model="adm.AdmObservation"
                  :height="150"
                />
                
              </v-col>
              <v-col cols="6" class="s-col-form">
                <s-textarea label="Sustento" v-model="adm.AdmReason"  :height="150"/>
              </v-col>
            </v-row>
            <v-row v-if="hiddenDate">
              <v-col cols="6" lg="6" md="6" class="s-col-form">
                <s-date
                  v-model="adm.AdmDateStart"
                  label="Fecha Inicio de Suspencion"
                />
              </v-col>
              <v-col cols="6" lg="6" md="6" class="s-col-form">
                <s-date
                  v-model="adm.AdmDateFinish"
                  label="Fecha Fin de Suspencion"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="s-col-form" lg="4" md="4" sm="6" xs="12">
                <s-select-definition
                  add
                  :def="1141"
                  v-model="adm.TypeLivelihood"
                  :text="adm.TypeLivelihoodText"
                  label="Tipo de Atencion"
                ></s-select-definition>
              </v-col>
            </v-row>
            <v-row v-if="isError == 1 ? true : false">
              <v-col class="s-col-form" lg="12" md="12" sm="12" xs="12">
                <v-alert
                  v-if="isError == 1 ? true : false"
                  dense
                  outlined
                  type="error"
                >
                  {{ Messages }}
                </v-alert>
                <v-alert v-else dense outlined type="success">
                  {{ Messages }}
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="s-col-form">
                <v-btn small block text @click="Clear($event)">Regresar</v-btn>
              </v-col>
              <v-col class="s-col-form">
                <v-btn
                  small
                  block
                  color="primary"
                  :disabled="isDisabled"
                  @click="SaveAdmonition($event)"
                  >Guardar</v-btn
                >
              </v-col></v-row
            >
          </v-container>
          <v-divider></v-divider>
          <v-col v-if="adm.AdmID > 0" style="padding-top: 20px">
            <v-row class="justify-center">
              <s-crud
                title="Manejo de Archivos"
                :config="config"
                @save="saveDocument($event)"
                @add="addDocument($event)"
                @edit="editDocument($event)"
                add
                edit
                :filter="filter"
                addNoFunction
                @rowSelected="rowSelected($event)"
                noBorder
                ref="scrudarchive"
              ></s-crud>
            </v-row>

            <v-dialog v-model="dialog" persistent max-width="350">
              <v-card>
                <v-card-title
                  class="text-center"
                  style="font-size: 14px !important"
                >
                  <b>Registro de Archivos</b>
                </v-card-title>
                <v-col cols="12" class="s-col-form">
                  <s-load-file @changeData="changeData($event)" />
                </v-col>
                <v-col cols="12" class="s-col-form">
                  <s-text label="Observacion" v-model="hdc.AdcObservation" />
                </v-col>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="CancelDocument($event)"
                    >Cancelar</v-btn
                  >
                  <v-btn color="primary" @click="saveDocument(hdc)"
                    >Guardar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="editfile" persistent max-width="350">
              <v-card>
                <v-card-title
                  class="text-center"
                  style="font-size: 14px !important"
                >
                  <b>Descargar Archivo</b>
                </v-card-title>
                <v-col cols="12" class="s-col-form">
                  <s-text label="Archivo" v-model="hdc.AdcFileName" disabled />
                </v-col>
                <v-col cols="12" class="s-col-form">
                  <s-text label="F.Subida" v-model="hdc.AdcDate" disabled />
                </v-col>
                <v-col cols="12" class="s-col-form">
                  <s-text
                    label="Observacion"
                    v-model="hdc.AdcObservation"
                    disabled
                  />
                </v-col>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="canceleditDocument($event)"
                    >Cancelar</v-btn
                  >
                  <v-btn color="error" @click="deleteDocument($event)"
                    >Eliminar</v-btn
                  >
                  <v-btn color="primary" @click="downloadFile($event)"
                    >Descargar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-card-text>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import _sAdmonition from "@/services/HumanResource/AdmonitionService";
import _sAdmonitionDocument from "@/services/HumanResource/AdmonitionDocumentService";
import sSelectWorker from "@/components/Utils/Worker/sSelectWorker";
import _sHelper from "@/services/HelperService";
import sToolbarWorker from "@/components/Utils/Worker/sToolbarWorker";
export default {
  components: { sSelectWorker, sToolbarWorker },
  props: {
    obj: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMessages: false,
      Messages: {
        type: String,
        default: "",
      },
      isError: {
        type: Number,
        default: false,
      },
      adm: {},
      hiddenDate: false,
      IsPayrollSecurity: true,
      filter: {
        AdmID: 0,
      },
      isDisabled: false,
      //gestion archivos
      dialog: false,
      editfile: false,
      hdc: {},
      config: {
        model: {
          AdcID: "ID",
          AdmID: "int",
          AdcLocation: "string",
          AdcObservation: "string",
          AdcDate: "date",
        },

        service: _sAdmonitionDocument,
        headers: [
          {
            width: "5%",
            align: "end",
            text: "ID",
            value: "AdcID",
            sorteable: true,
          },
          {
            text: "Archivo",
            value: "AdcFileName",
            sorteable: true,
          },
          {
            width: "25%",
            align: "center",
            text: "Fecha",
            value: "AdcDate",
          },
        ],
      },
    };
  },
  created() {
    this.filter.AdmID = this.obj.AdmID;
    this.adm.AdmID = this.obj.AdmID;
    //this.initialize();
  },
  watch: {
    obj() {
      console.log(this.obj);
      this.adm.AdmID = this.obj.AdmID;
      this.initialize();
    },
    filter() {
      console.log("entrando al wach");
      this.filter.AdmID = this.adm.AdmID;
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      this.adm.AdmID = this.obj.AdmID;
      //this.initialize();
    });
  },
  computed: {
    messages() {
      return this.showMessages
        ? this.Messages
        : this.showMessages == 0
        ? undefined
        : this.Messages;
    },
  },
  methods: {
    initialize() {
      this.$nextTick().then(() => {
        console.log(this.adm);
        if (this.adm.AdmID > 0) {
          _sAdmonition.get(this.adm, this.$fun.getUserID()).then((x) => {
            this.adm = x.data;
            this.adm.DataWorker = { ...x.data };
            this.filter.AdmID = this.adm.AdmID;
            this.showMessages = this.adm.CtrStatus;
            this.Messages =
              this.adm.CtrStatus == 2 ? "CONTRATO DE BAJA" : "CONTRATO ACTIVO";
            this.isError = this.adm.CtrStatus == 2 ? true : false;;
          });
        } else {
          this.adm = {
            TypePayroll: 0,
            AdmObservation: "",
            AdmReason: "",
            AdmID: 0,
            AdmDateFinish: this.$fun.getDate(),
            AdmDateStart: this.$fun.getDate(),
            TypeLivelihood: 0,
          };
          this.filter.AdmID = 49;
        }
        this.isDisabled = false;
      });
    },
    selectAdmonition(value) {
      if (value != null) {
        if (value == 2) this.hiddenDate = true;
        else this.hiddenDate = false;
      }
    },

    blockadd(item) {
      if (this.edit) {
      } else {
        this.isDisabled = true;
        this.adm.AdmID = item;
        this.filter.AdmID = item;
      }
    },

    Clear() {
      this.$emit("clear"); // en el padre limpio ya que alli esta el crud
    },
    rowSelected(items) {
      if (items.length > 0) {
        this.hdc = items[0];
      } else this.hdc = {};
    },
    saveDocument(item) {
      if (this.files != null)
        this.$fun.alert("Seguro de subir Archivo", "question").then((val) => {
          if (val.value) {
            if (this.config != null)
              if (this.files != null) {
                var formData = new FormData();
                formData.append("file", this.files);
                _sHelper
                  .uploadfile(
                    this.adm.AdmID,
                    this.$fun.getUserID(),
                    formData,
                    1
                  )

                  .then(() => {
                    this.$fun.alert("Archivo cargado correctamente", "success");
                  });
              } else this.$fun.alert("Seleccione Archivo a Cargar", "info");

            var adc = {
              AdmID: this.adm.AdmID,
              AdcFileName: this.files.name,
              AdcObservation: item.AdcObservation,
              SecStatus: 1,
            };
            _sAdmonitionDocument
              .save(adc, this.$fun.getUserID())
              .then((response) => {
                if (response.status == 200) {
                  this.$refs.scrudarchive.refresh();
                  if (adc.AdcID == 0) {
                    this.$fun.alert("Agregado Correctamente", "success");
                  } else {
                    this.$fun.alert("Actualizado Correctamente", "success");
                  }
                }
              });

            this.CancelDocument();
          }
        });

      //guardando documentos
    },

    addDocument(value) {
      this.dialog = true;
      this.hdc.AdcObservation = "";
    },
    editDocument(value) {
      this.editfile = true;
    },
    CancelDocument() {
      console.log("cerrando");
      this.$refs.scrudarchive.refresh();
      this.dialog = false;
    },
    canceleditDocument() {
      this.editfile = false;
    },
    deleteDocument() {
      var adc = {
        AdcID: this.hdc.AdcID,
        AdmID: this.hdc.AdmID,
        AdcFileName: this.hdc.AdcFileName,
        AdcObservation: "Archivo Eliminado",
        SecStatus: 0,
      };

      this.$fun.alert("Seguro de eliminar Archivo", "question").then((val) => {
        if (val.value) {
          _sHelper
            .delete(
              this.hdc.AdmID,
              this.$fun.getUserID(),
              1,
              this.hdc.AdcFileName
            )
            .then(() => {
              _sAdmonitionDocument
                .save(adc, this.$fun.getUserID())
                .then((response) => {
                  if (response.status == 200) {
                    if (adc.AdcID == 0) {
                      this.$fun.alert("Agregado Correctamente", "success");
                    } else {
                      this.$fun.alert("Actualizado Correctamente", "success");
                    }
                  }
                  this.$refs.scrudarchive.refresh();
                });
            });

          this.editfile = false;
        } else this.$fun.alert("error de archivo", "info");
      });
    },
    //
    changeData(item) {
      this.files = item;
    },
    downloadFile() {
      if (this.hdc.AdcFileName != null) {
        _sHelper
          .getfile(
            this.hdc.AdmID,
            this.$fun.getUserID(),
            this.hdc.AdcFileName,
            1
          )
          .then((r) => {
            this.$fun.download(r.data, this.hdc.AdcFileName);
          });
      }
    },
    //-------Refactarizacion---------------
    GetReturnPerson(value, item) {
      console.log(value);
      if (value != null) {
        this.adm.WkrName = value.NtpFullName;
        this.adm.TypeDocument = value.TypePersonDocument;
        this.adm.PrsDocumentNumber = value.PrsDocumentNumber;
        this.adm.WkrID = value.WkrID;
        this.adm.NtpID = value.NtpID;
        this.adm.CtmID = value.CtmID;
        this.adm.CtrStatus = value.CtrStatus;
        this.showMessages = value.CtrStatus;
        this.Messages =
          value.WkrID == 0 ? "NO ES TRABAJADOR" : value.MahMessage;
        this.isError = value.TypeError;
      }
    },
    SaveAdmonition() {
      var ctr = this.$fun.getUserInfo(); //---- Obtengo los datos del contrato

      console.log("AdmDateStart sa", this.adm.AdmDateStart)

      var adm = {
        AdmID: this.adm.AdmID,
        WkrID: this.adm.WkrID,
        NtpID: this.adm.NtpID,
        CtmID: this.adm.CtmID,
        CtrStatus: this.adm.CtrStatus,
        TypeAdmonition: this.adm.TypeAdmonition,
        AdmObservation: this.adm.AdmObservation,
        AdmReason: this.adm.AdmReason,
        AdmDateStart:
          this.adm.AdmDateStart == undefined
            ? "1900-01-01"
            : this.adm.AdmDateStart,
        AdmDateFinish:
          this.adm.AdmDateFinish == undefined
            ? "1900-01-01"
            : this.adm.AdmDateFinish,
        UsrID: this.$fun.getUserID(),
        SecStatus: 1,
        PstUserID: ctr.CtrContract.CtrID,
        TypePayroll: this.adm.TypePayroll,
        TypeLivelihood: this.adm.TypeLivelihood,
      };

      var superadmin = false;
      console.log('this.adm.CtrStatus ', this.adm.CtrStatus);
      console.log('obj send ', adm);

      if (this.adm.CtrStatus == 1) superadmin = true;

      if (this.adm.CtrStatus == 2)
        superadmin = this.$fun.getSecurity().IsLevelAdmin;

      if (superadmin) {
        if (this.isValidate(adm)) {
          return _sAdmonition
            .save(adm, this.$fun.getUserID())
            .then((response) => {
              if (response.status == 200) {
                if (adm.AdmID == 0) {
                  this.$fun.alert("Agregado Correctamente", "success");
                } else {
                  this.$fun.alert("Actualizado Correctamente", "success");
                }
                this.blockadd(response.data.AdmID);
              }
            });
        }
      } else {
        console.log(superadmin);
        this.$fun.alert("No tiene acceso para estos registro", "warning");
      }
    },
    isValidate(item) {
      let isValid;
      let message;

      isValid = item.TypeAdmonition > 0;
      if (!isValid) message = "Seleccione tipo de admonestacion";
      else {
        isValid = item.WkrID > 0;
        if (!isValid) message = "Seleccione trabajor";
        else {
          isValid = item.AdmObservation != null;
          if (!isValid) message = "Registre la descripcion de la falta ";
          else {
            isValid = item.AdmObservation.length > 5;
            if (!isValid)
              message =
                "La descripcion de la falta, debe contener mas caracteres";
            else {
              isValid = item.AdmReason != null;
              if (!isValid)
                message =
                  "El sustento de la falta, debe contener mas caracteres";
              else {
                isValid = item.AdmReason.length > 5;
                if (!isValid)
                  message =
                    "El sustento de la falta, debe contener mas caracteres";
              }
            }
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
  },
};
</script>
