<template>
    <div class="d-flex align-center flex-column">
        <v-card>
            <s-toolbar
                label="Impresion Amonestacion PDF"
                dark
                :color="'#BAB6B5'"
                close
                @close="$emit('close')"
                class="mb-6">
            </s-toolbar>
            <v-card-title style="font-size: small;">
				<v-row>					
					<v-col align="right">
						<v-btn 
							fab 	
							x-small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
            <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="true"
                :filename="'N°' + this.admonitionSelected[0].AdmDocumentNumber + '-' + moment(this.admonitionSelected[0].AdmDate).format('YYYY') + '-JAPC-RRHH' "
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">	
                <section slot="pdf-content"> 
                    <v-container style="margin:30px 0px 0px 0px;padding:0px;" class="bg-surface-variant">                    
                        <v-row style="margin: 5px 40px 5px 40px;;padding: 0px;" justify="center">
                            <div id="contenido">
                                <header>
                                    <img width='200' src='../../../public/static/logo_sp.png' 
                                        style="position: relative;left: 3%;"/> 
                                </header>
                                <div style="font-family: Tahoma,  sans-serif;font-size: 12px;" >
                                    <center>
                                        <p><strong>MEMORANDUM<br>
                                        N°{{ this.admonitionSelected[0].AdmDocumentNumber}}-{{ moment(this.admonitionSelected[0].AdmDate).format('YYYY')}}-JAPC-RRHH</strong></p>
                                    </center>
                                </div>
                                <div id="contenido2">
                                    <table>
                                        <tr>
                                            <td style="font-family:Tahoma,  sans-serif;font-size:12px;width: 150px;">PARA:</td>
                                            <td style="font-family:Tahoma,  sans-serif;font-size:12px">{{ this.admonitionSelected[0].WkrName }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:Tahoma,  sans-serif;font-size:12px;width: 150px;">{{this.admonitionSelected[0].TypeDocumentText}}:</td>
                                            <td style="font-family:Tahoma,  sans-serif;font-size:12px">{{ this.admonitionSelected[0].PrsDocumentNumber}}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:Tahoma, sans-serif;font-size:12px;width: 150px;">AREA:</td>
                                            <td style="font-family:Tahoma, sans-serif;>font-size:12px">{{ this.admonitionSelected[0].AreName }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:Tahoma, sans-serif;font-size:12px;width: 150px;">CARGO:</td>
                                            <td style="font-family:Tahoma, sans-serif;font-size:12px">{{ this.admonitionSelected[0].PstName }}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:Tahoma, sans-serif;font-size:12px;width: 150px;">ASUNTO:</td>
                                            <td style="font-family:Tahoma, sans-serif;font-size:12px">{{ this.admonitionSelected[0].TypeAdmonitionText}}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-family:Tahoma, sans-serif;font-size:12px;width: 150px;">FECHA:</td>
                                            <td style="font-family:Tahoma, sans-serif;font-size:12px">{{ moment(this.admonitionSelected[0].AdmDateLarge).format('DD/MM/yyyy hh:mm')  }}</td>
                                        </tr>
                                    </table>
                                </div>
                                
                                <hr id="contenido2">
                                
                                <div id="contenido2" style="height: 150px;">
                                    <p style="font-family:Tahoma, sans-serif;font-size:12px;">Por medio de la presente le empresa <strong>SUNSHINE EXPORT S.A.C. </strong> identificada con <strong>R.U.C: 20329725431,</strong> centro 
                                        de labores donde usted labora, le comunica que:<br> 
                                        {{ this.admonitionSelected[0].AdmObservation }}.</p>
                                </div>
                                
                                <div id="contenido2">
                                    <p style="font-family:Tahoma, sans-serif;font-size:12px;">En consecuencia usted ha cometido una falta disciplinaria que se encuentra dentro de los siguientes parametros 
                                        normativos:</p>
                                </div>
                                <div id="contenido2" style="border: 2px solid black; 
                                padding:0em 0.5em;height: 120px;">
                                    <p style="font-size:12px;">{{ this.admonitionSelected[0].AdmReason }}</p>
                                </div>
                                <div id="contenido2" style="height: 80px;" >
                                    <p style="font-family:Tahoma, sans-serif;font-size:12px">Por lo tanto se emite la siguiente amonestacion por motivo: {{ this.admonitionSelected[0].TypeAdmonitionText + ' '}} {{ this.admonitionSelected[0].Comentary }}</p>
                                </div>
                                <div id="contenido2">
                                    <center>
                                        <div style="height: 0px;"></div>
                                        <img width='170' src='../../../public/static/firmaamonestaciones.png' 
                                            style="position: relative;"/> 
                                        <p style="font-family:Tahoma,  sans-serif;font-size:11px;font-weight: bold;margin-top: -15px;"> 
                                            ........................................................................<br>
                                            Atentamente <br>
                                        <!-- <strong>{{ this.admonitionSelected[0].Boss }}</strong><br>
                                           <strong>{{ this.admonitionSelected[0].PstPositionBoss }}</strong>--> 
                                           <strong>VALQUI CASTAÑEDA LIDIA RAQUEL</strong><br>
                                           <strong>JEFE DE ADMINISTRACION DE PERSONAL Y COMPENSACIONES</strong> 
                                        </P> 
                                    </center>
                                    
                                </div>
                            </div>
                        </v-row>
                    </v-container>
                </section>
            </vue-html2pdf>
        </v-card>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
    export default{ 
        props:{
            admonitionSelected: {
				type: Array,
				default: null,
			},
        },
        components:{
            VueHtml2pdf
        },
        data(){
            return{

            }
        },
        methods:{
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            onProgress(progress) {
				this.progress = progress;
				console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
				console.log(`On Before PDF Generation`)
					 html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
							const totalPages = pdf.internal.getNumberOfPages()
							for (let i = 1; i <= totalPages; i++) {
								pdf.setPage(i)
								pdf.setFontSize(10)
								pdf.setTextColor(150)
								pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
							} 
						}).save()
				},
        },
        mounted(){
            console.log('print', this.admonitionSelected);
        }
    }
</script>

<style>
    #contenido {
        width: 800px;
        margin: 0 auto;
        padding: 10px;
    }
    #contenido2 {
        
        margin: 20px;
        /* padding: 10px; */
    }
</style>