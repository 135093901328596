<template>
  <v-row>
    <v-col>
      <v-card>
        <v-tabs :value="tab" vertical ref="tabAdmonition">
          <v-tab-item value="tab-show">
            <s-crud
              title="Amonestaciones"
              @add="addAdmonition($event)"
              @edit="editAdmonition($event)"
              :config="config"
              add
              edit
              remove
              addNoFunction
              pdf
              excel
              @pdf="pdf($event)"
              @rowSelected="rowSelected"
              @doubleClick="doubleClick"
              @save="saveAdm()"
              ref="crudAdmonition"
              :filter="filter"
              searchInput
            >
              <template v-slot:filter>
                <v-card elevation="0">
                  <v-card-text>
                    <v-row justify="center">
                      <v-col
                        cols="3"
                        class="s-col-form"
                        v-if="$fun.getSecurity().IsLevelPayroll"
                      >
                        <s-select-payroll-allow
                          v-model="filter.TypePayroll"
                          label="Tipo Planilla"
                        />
                      </v-col>
                      <v-col
                        cols="3"
                        class="s-col-form"
                        v-if="$fun.getSecurity().IsLevelAdmin"
                      >
                        <s-select-definition
                          :def="1060"
                          v-model="filter.TypePayroll"
                          label="Tipo Planilla"
                        />
                      </v-col>
                      <v-col cols="3" class="s-col-form">
                        <s-select-definition
                          :def="1052"
                          v-model="filter.TypeAdmonition"
                          label="Tipo Amonestación"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </s-crud>
          </v-tab-item>
          <v-tab-item value="tab-edit">
            <admonition-edit
              @clear="clearCrud()"
              @callSave="callSave()"
              :obj="entityShow"
              :edit="edit"
            />
          </v-tab-item>
        </v-tabs>
        <v-dialog v-if="dlgadmonitionpdf" v-model="dlgadmonitionpdf"
          persistent fullscreen>
          <admonition-pdf :admonitionSelected="entity" @close="dlgadmonitionpdf = false"></admonition-pdf>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
  
</template>
<script>
import AdmonitionEdit from "@/views/HumanResource/Admonition/AdmonitionEdit.vue";
import _sAdmonition from "@/services/HumanResource/AdmonitionService";
import AdmonitionPdf from "./AdmonitionPDF.vue";
export default {
  components: {
    AdmonitionEdit, AdmonitionPdf
  },
  computed: {
    config() {
      return {
        service: _sAdmonition,

        model: {
          
          AdmID: "ID",
          WkrID: "int",
          TypeAdmonition: "int",
          NtpID: "int",
          CtmID: "int",
          TypeAdmonitionText: "string",
          AreName: "string",
          WkrName: "string",
          AdmDate: "date",
          AdmDateStart: "date",
          AdmDateFinish: "date",
          TypePayroll: "int",
        },
        headers: [
          {
            text: "ID",
            align: "start",
            width: "5%",
            value: "AdmID",
          },
          {
            text: "N°.Documento",
            align: "start",
            width: "10%",
            value: "AdmDocumentNumber",
          },
          {
            text: "Tipo Amonestación",
            sortable: true,
            align: "start",
            width: "20%",
            value: "TypeAdmonitionText",
          },
          {
            text: "Trabajador",
            sortable: true,
            value: "WkrName",
          },
          {
            text: "Fecha",
            align: "center",
            value: "AdmDate",
          },
          {
            text: "F. Inicio",
            align: "center",
            value: "AdmDateStart",
          },
          {
            text: "F. Fin",
            align: "center",
            value: "AdmDateFinish",
          },
        ],
      };
    },
  },
  data() {
    return {
      dlgadmonitionpdf: false,
      tab: "tab-show",
      entity: {},
      entityShow: {},
      filter: {
        TypeAdmonition: 1,
        TypePayroll: 5,
        SecSatus: 0,
      },
      edit: false,
    };
  },
  methods: {
    clearCrud() {
      this.$refs.crudAdmonition.refresh();
      this.tab = "tab-show";
    },
    addAdmonition(item) {
      this.tab = "tab-edit";
      this.entityShow = {};
      this.edit = false;
      console.log(item);
    },
    editAdmonition(item) {
      this.tab = "tab-edit";
      this.entityShow = item;
      this.edit = true;
    },

    callSave() {
      this.$refs.crudAdmonition.restart();
      this.tab = "tab-show";
    },

    doubleClick(item) {
      this.$emit("doubleClick", item);
    },
    rowSelected(item) {
      this.entity = item;
    },
    doubleClick(item) {
      this.entityShow = item[0];
    },
    pdf(item) {
      this.dlgadmonitionpdf = true;
      // if (item != null) {
      //   this.selected = item;
      //   console.log(item);
      //   this.$nextTick().then(() => {
      //     _sAdmonition.reportpdf(item, this.$fun.getUserID()).then((r) => {
      //       this.$fun.downloadFile(
      //         r.data,
      //         this.$const.TypeFile.PDF,
      //         this.selected.TypeAdmonitionText
      //       );
      //     });
      //   });
      // }
    },
  },
};
</script>
