import Service from "../Service";

const resource = "admonition/";

export default {
    get(adm, requestID) {
        return Service.post(resource, adm, {
            params: { requestID: requestID },
        });
    },
    save(adm, requestID) {
        return Service.post(resource + "save", adm, {
            params: { requestID: requestID },
        });
    },

    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },
    reportpdf(adm, requestID) {
        return Service.post(resource + "report", adm, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
    excel(dtr) {
        console.log(dtr)
        return Service.post(resource + "reportexcel", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                typepayroll: dtr.filter.TypePayroll,
                type: dtr.filter.TypeAdmonition
            },
        });
    },



};